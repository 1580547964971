import { gql } from '@apollo/client'


 
export const DELETE_LICENCE = gql`
  mutation DeleteLicence($adminId: String!, $licenceId: String!) {
    deleteLicence(adminId: $adminId, licenceId: $licenceId) {
    message
  }
  }
`; 

 
export const DELETE_COSTUMER = gql`
  mutation DeleteCostumer($adminId: String!, $costumerId: String!) {
    deleteCostumer(adminId: $adminId, costumerId: $costumerId) {
        message
  }
  }
`;

export const ADD_LICENCE = gql`
  mutation AddLicence($costumerId: String!, $productionCode: String!, $appVersion: String!, $maximumNumberOfMachines: Int!, $expirationDate: String, $featuresF1: Boolean, $notes: String, $featuresF2: Boolean, $featuresF3: Boolean, $featuresF4: Boolean, $featuresF5: Boolean){
  addLicence(costumerId: $costumerId, productionCode: $productionCode, appVersion: $appVersion, maximumNumberOfMachines: $maximumNumberOfMachines, expirationDate:  $expirationDate, featuresF1: $featuresF1, notes: $notes,featuresF2: $featuresF2, featuresF3: $featuresF3, featuresF4: $featuresF4, featuresF5: $featuresF5) {
    message
  }
}
`;
export const EDIT_LICENCE = gql`
  mutation EditLicence($licenceId: String!, $productionCode: String!, $appVersion: String!, $maximumNumberOfMachines: Int!, $expirationDate: String, $featuresF1: Boolean, $featuresF2: Boolean, $featuresF3: Boolean, $featuresF4: Boolean, $featuresF5: Boolean, $notes: String){
    editLicence(licenceId: $licenceId, productionCode: $productionCode, appVersion: $appVersion, maximumNumberOfMachines: $maximumNumberOfMachines, expirationDate: $expirationDate, featuresF1: $featuresF1, featuresF2: $featuresF2, featuresF3: $featuresF3, featuresF4: $featuresF4, featuresF5: $featuresF5, notes: $notes) {
      message
    }
  }
`;



 
export const LOGIN_ADMIN = gql`
  mutation LoginAdmin($email: String!, $password: String!) {
    loginAdmin(email: $email, password: $password) {
      token
    }
  }
`;


export const REMOVE_MACHINE = gql`
    mutation RemoveMachine($licenceId: String!, $macAddress: String!){
  removeMachine(licenceId: $licenceId, macAddress: $macAddress) {
    isActiveForUsing
    isBlocked
  }
}
`

export const NEW_BULK_EMAIL = gql`
mutation NewEmailForUsers($userId: String!, $mesaj: String!, $baslik: String!, $userIds: [String!]) {
  newEmailForUsers(userId: $userId, mesaj: $mesaj, baslik: $baslik, userIds: $userIds) {
  message  
  }
}
`


