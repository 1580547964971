import React from 'react';
import { Container, Row, Col, Card, Badge, Button } from 'react-bootstrap';
import { FaEnvelope } from 'react-icons/fa'; // E-posta simgesi için

const Dashboard = ({
  totalCostumer,
  licenceCount,
  licenceDemoCount,
  machineCount,
  handleBulkEmailSend,
}) => {
  return (
    <Container fluid >
      {/* Floating Action Button */}
      <div
        style={{
          position: 'fixed',
          top: '20px',
          right: '20px',
          zIndex: 1000,
        }}
      >
        <Button
          variant="danger"
          size="lg"
          style={{
            borderRadius: '50%',
            width: '60px',
            height: '60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={handleBulkEmailSend}
          title="Toplu E-posta Gönder"
        >
          <FaEnvelope size={24} />
        </Button>
      </div> 

      {/* Bilgi Kartları */}
      <Row className="gy-4">
        {[
          { title: 'Toplam Kullanıcı', count: totalCostumer, bg: 'primary', icon: '👥' },
          { title: 'Lisans Sayısı', count: licenceCount, bg: 'success', icon: '🔑' },
          { title: 'Demo Kullanıcı', count: licenceDemoCount, bg: 'danger', icon: '🧪' },
          { title: 'Makine Sayısı', count: machineCount, bg: 'secondary', icon: '🖥️' },
        ].map(({ title, count, bg, icon }, index) => (
          <Col key={index} xs={12} md={6} lg={3}>
            <Card className={`h-100 shadow border-0 bg-${bg} text-white`}>
              <Card.Body className="text-center d-flex flex-column justify-content-center align-items-center">
                <div className="display-4">{icon}</div>
                <Card.Title className="mt-2">{title}</Card.Title>
                <h3>
                  <Badge bg="light" text="dark">{count} Adet</Badge>
                </h3>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Dashboard;
