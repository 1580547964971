import { gql } from '@apollo/client'


export const USER_ADMIN_HOME_PROGILE = gql`
    query userAdminHomeProfile($userId:String!, $offset:Int, $limit:Int, $searchText:String){
        userAdminHomeProfile(userId:$userId, offset:$offset, limit:$limit, searchText:$searchText){
            licenceCount
            licenceDemoCount
            machineCount
            totalCostumer
            user {
                _id
                email
                role
                username
            }
            paginationCostumers{
                costumers {
                    _id
                    company
                    email
                    username
                    licences {
                        _id
                        appVersion
                        expirationDate
                        featuresF1
                        maximumNumberOfMachines
                        productionCode
                        updatedAt
                    }
                }
                pageInfo {
                    currentPage
                    hasNextPage
                    hasPreviousPage
                    totalCount
                }
            }
        }
    }
` 


export const USER_ADMIN_BULK_MAIL_USERS =  gql`
query UserAdminBulkMailUserInfo($userId: String!) {
  userAdminBulkMailUserInfo(userId: $userId) {
 costumers {
  _id
  city
  company
  email
  licences {
    _id
    appVersion
    expirationDate
    maximumNumberOfMachines
    updatedAt
    machines {
      _id
    }
  }
 }
  }
}`

  