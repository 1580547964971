import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Form, FormControl, Spinner, Pagination } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { USER_ADMIN_HOME_PROGILE } from '../../../Graphql/Queries/User';
import { checkToken } from '../../../services/Auth';
import UserListItem from './UserListItem';
import Dashboard from './Dashboard';

const AdminPage = () => {
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState(''); // Arama metni
    const [searchTextSec, setSearchTextSec] = useState(''); // Arama metni geçici değişkeni
    const [currentPage, setCurrentPage] = useState(1);
    let userId = localStorage.getItem('currentUserId');
    let limit = 25;
    const offset = (currentPage - 1) * limit;

    const { loading, error, data, refetch, fetchMore } = useQuery(USER_ADMIN_HOME_PROGILE, {
        variables: { userId, offset, limit, searchText },
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        refetch();
        if (!checkToken()) {
            navigate('/login');
        }
    }, [refetch, navigate]);

    if (loading) {
        return <Spinner animation="border" role="status" />;
    }

    if (error) {
        return (
            <Container style={{ marginTop: '10%' }}>
                <div className="d-flex justify-content-center align-items-center">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Veriler yüklenirken hata alındı...</span>
                        <span className="sr-only">{error.message}</span>
                    </Spinner>
                </div>
            </Container>
        );
    }

    const { userAdminHomeProfile } = data;
    const { totalCostumer = 0, licenceCount = 0, licenceDemoCount = 0, machineCount = 0, paginationCostumers = [] } = userAdminHomeProfile;
    const { costumers, pageInfo } = paginationCostumers;

    const { hasNextPage, hasPreviousPage } = pageInfo;

    const handleFetchMore = (offset) => {
        fetchMore({
            variables: {
                offset,
                limit,
                searchText
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                setCurrentPage(fetchMoreResult.userAdminHomeProfile.paginationCostumers.pageInfo.currentPage);
                return {
                    userAdminHomeProfile: {
                        ...prev.userAdminHomeProfile,
                        paginationCostumers: {
                            ...prev.userAdminHomeProfile.paginationCostumers,
                            costumers: [...fetchMoreResult.userAdminHomeProfile.paginationCostumers.costumers],
                            pageInfo: fetchMoreResult.userAdminHomeProfile.paginationCostumers.pageInfo,
                        },
                    },
                };
            },
        });
    };

    const handleSearch = () => {
        if (searchTextSec !== '' && searchTextSec.length >= 3) {
            setSearchText(searchTextSec);
            setCurrentPage(1); // Sayfayı başa al
        }
    };

    // Sayfa numaralarını oluştur
    const items = [];
    for (let number = 1; number <= Math.ceil(totalCostumer / limit); number++) {
        items.push(
            <Pagination.Item
                key={number}
                active={number === currentPage}
                onClick={() => handleFetchMore((number - 1) * limit)}
            >
                {number}
            </Pagination.Item>
        );
    }

    const handleBulkEmailSend = () => {
        console.log("Toplu e-posta gönderme butonuna tıklandı!");
        navigate('/admin/bulkemail', {
            state: {
                userList: costumers,
            },
        });
    };

    return (
        <Container style={{ marginTop: '5%' }}>
            <Dashboard licenceCount={licenceCount} totalCostumer={totalCostumer} licenceDemoCount={licenceDemoCount} machineCount={machineCount} handleBulkEmailSend={handleBulkEmailSend} />

            <Col xs={12} className="mb-4 mt-4 ">
                <Form className="d-flex">
                    <FormControl
                        type="text"
                        placeholder="Ara..."
                        value={searchTextSec}
                        onChange={event => setSearchTextSec(event.target.value)}
                        className="me-2"
                        onKeyPress={e => e.key === 'Enter' && handleSearch()} // Enter tuşuna basıldığında arama yap
                    />
                    <Button onClick={handleSearch}>Ara</Button>
                </Form>
            </Col>
            <Row>
                {costumers.map((user, index) => (
                    <UserListItem key={index} user={user} adminId={userId} refreshMain={refetch} />
                ))}
            </Row>

            {/* Sayfalama işlevselliği */}
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                <Pagination size="lg">
                    <Pagination.Prev onClick={() => handleFetchMore((currentPage - 2) * limit)} disabled={!hasPreviousPage} />
                    {items}
                    <Pagination.Next onClick={() => handleFetchMore(currentPage * limit)} disabled={!hasNextPage} />
                </Pagination>
            </div>
        </Container>
    );
};

export default AdminPage;
