import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Container, Row, Col, Form, Button, Card, ListGroup, Modal } from 'react-bootstrap';
import { USER_ADMIN_BULK_MAIL_USERS } from '../../../Graphql/Queries/User';
import { NEW_BULK_EMAIL } from '../../../Graphql/Mutations/AdminUser';

const BulkEmailPage = () => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [showDialog, setShowDialog] = useState(false); // Modal Dialog state
  const [dialogMesaj, setDialogMesaj] = useState(""); // Modal Dialog state

  const [NewEmailForUsers, dataBulkEmailData] = useMutation(NEW_BULK_EMAIL)
  const userId = localStorage.getItem('currentUserId');

  const { loading, error, data } = useQuery(USER_ADMIN_BULK_MAIL_USERS, {
    variables: { userId },
    fetchPolicy: 'cache-and-network',
  });

  if (loading) return <p>Yükleniyor...</p>;
  if (error) return <p>Hata: {error.message}</p>;

  const userList = data?.userAdminBulkMailUserInfo.costumers || [];

  const handleUserSelection = (userId) => {
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.includes(userId)
        ? prevSelectedUsers.filter((id) => id !== userId)
        : [...prevSelectedUsers, userId]
    );
  };

  const handleSelectAll = () => setSelectedUsers(userList.map((user) => user._id));
  const handleClearSelection = () => setSelectedUsers([]);

  const handleSendEmails = async () => {
    console.log('Seçilen Kullanıcılar:', selectedUsers);
    console.log('Konu:', subject);
    console.log('Mesaj:', message);

    // E-posta gönderme işlemi (Simülasyon)
    // Burada NewEmailForUsers çağrısını aktif hale getirebilirsiniz.
    var {data} = await NewEmailForUsers({
      variables: {
        userId,
        userIds: selectedUsers,
        mesaj: message,
        baslik: subject,
      },
    });

    console.log(dataBulkEmailData);
    

    
    let dataMesaj= data?.newEmailForUsers.message 
    setDialogMesaj(dataMesaj)
    setShowDialog(true); 
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSendEmails();
  };

  // Dialog (Modal) penceresinin kapanması
  const handleCloseDialog = () => {
    setSubject('');
    setMessage('');
    setSelectedUsers([]);
    setShowDialog(false)};

  return (
    <Container fluid style={{ marginTop: '5%' }}>
          <Row >
              <Col md={6}>
              <Card className="shadow border-0">
            <Card.Body>
              <h5>Mesaj Bilgileri</h5>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="emailSubject" className="mb-3">
                  <Form.Label>Konu</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="E-posta konusu girin"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="emailMessage" className="mb-3">
                  <Form.Label>Mesaj</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={6}
                    placeholder="E-posta mesajını yazın"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Form.Group>

                {/* Gönder Butonu */}
                <div className="d-grid gap-2">
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={!selectedUsers.length || !subject || !message || dataBulkEmailData.loading}
                  >
                    {dataBulkEmailData.loading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Gönderiliyor...
                      </>
                    ) : (
                      "Gönder"
                    )}
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>

      </Col>
      <Col md={6} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Card className="shadow border-0" style={{ flex: 1 }}>
      <Card.Body>
            <h5>Kullanıcıları Seçin</h5>
            <div className="d-flex justify-content-between mb-3">
              <Button variant="success" onClick={handleSelectAll}>
                Hepsini Seç
              </Button>
              <Button variant="danger" onClick={handleClearSelection}>
                Seçimi Temizle
              </Button>
            </div>
            <ListGroup style={{ maxHeight: '300px', overflowY: 'auto' }}>
              {userList.map((user) => (
                <ListGroup.Item
                  key={user._id}
                  className={`d-flex justify-content-between align-items-center ${selectedUsers.includes(user._id) ? 'bg-light' : ''}`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleUserSelection(user._id)}
                >
                  <div>
                    <strong>{user.name}</strong>
                    <p style={{ margin: 0 }}><small>{user.email}</small></p>
                    <p style={{ margin: 0 }}><small>Firma: {user.company}</small></p>
                  </div>
                  <Form.Check
                    type="checkbox"
                    checked={selectedUsers.includes(user._id)}
                    onChange={() => handleUserSelection(user._id)}
                  />
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card.Body>
        </Card>
      </Col>

   
    </Row>
    
    <Row className="mb-4">
      <Col>
        <h2 className="text-center text-white">Toplu E-posta Gönderimi</h2>
      </Col>
    </Row>



    {/* Email Gönderme Sonucu Dialog */}
    <Modal show={showDialog} onHide={handleCloseDialog}>
  <Modal.Header closeButton>
    <Modal.Title>E-posta Gönderimi Tamamlandı</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <p>{selectedUsers.length} kullanıcısına e-posta gönderildi.</p>
    <p><strong>Konu:</strong> {subject}</p>
    <p><strong>Mesaj İçeriği:</strong></p>
    <div
      dangerouslySetInnerHTML={{
        __html: message // HTML içeriği burada render ediliyor
      }}
    />
    <p
      dangerouslySetInnerHTML={{
        __html: dialogMesaj // HTML içeriği burada render ediliyor
      }}
    />
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleCloseDialog}>
      Kapat
    </Button>
  </Modal.Footer>
</Modal>

  </Container>
  );
};

export default BulkEmailPage;
