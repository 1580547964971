import React, { useState, useEffect } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Footer from "./components/Footer";
import Resume from "./components/Resume/ResumeNew";
import Etex from "./components/ProductDetail/Etex/Etex";
import Netex from "./components/ProductDetail/Netex/Netex";
import BetonarmeAraclar from "./components/ProductDetail/BetonarmeAraclar/BetonarmeAraclar";
import Costumer from "./components/Screens/Costumer/Costumer";
import AdminPage from "./components/Screens/Admin/AdminPage";
import LicenceAdd from "./components/Screens/Admin/LicenceAdd";
import { BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom";

import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./app.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ForgatPassword from "./components/Auth/ForgatPassword/ForgatPassword";
import ChangePasswordPage from "./components/Auth/ChangePassword/ChangePassword";
import LoginPage from "./components/Auth/Login/Login";
import RegisterPage from "./components/Auth/Register/Register";
import { ApolloProvider } from '@apollo/client';
import { client } from './Graphql/ApolloClient'
import LicenceEdit from "./components/Screens/Admin/LicenceEdit";
import LoginPageAdmin from "./components/Auth/LoginAdmin/LoginAdmin";
import BulkEmailPage from "./components/Screens/Admin/BulkEmail";
 

function App() {
  const [load, upadateLoad] = useState(true); 
  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200); 
 
    return () => clearTimeout(timer);
  }, [ ]);
 
  return (
    <ApolloProvider client={client}>
      <Router>
        <Preloader load={load} />
        <div className="App" id={load ? "no-scroll" : "scroll"}>
          <Navbar />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/project" element={<Projects />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Resume />} />
            <Route path="/etex" element={<Etex />} />
            <Route path="/netex" element={<Netex />} />
            <Route path="/BetonarmeAraclar" element={<BetonarmeAraclar />} />
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/login" element={<LoginPage />}/>
            <Route path="/register" element={<RegisterPage />}/>
            <Route path="/costumer" element={<Costumer />} />
            <Route path="/changepassword" element={<ChangePasswordPage />} />
            <Route path="/forgotpassword" element={<ForgatPassword />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/admin/addnewlicence" element={<LicenceAdd />} />
            <Route path="/admin/editlicence" element={<LicenceEdit />} />
            <Route path="/admin/login" element={<LoginPageAdmin />} />
            <Route path="/admin/bulkemail" element={<BulkEmailPage />} />
           </Routes>
          <Footer />
        </div>
      </Router>
    </ApolloProvider>
  );
}

export default App;
